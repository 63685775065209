<template>
  <section class="wrap">
    <div class="content">
      <h3>订单信息</h3>
      <div class="info" v-if="orderData">
        <div class="item">
          订单类型：{{ orderData.buyType == 1 ? "个人" : "单位" }}
        </div>
        <div class="item">订单状态：已支付</div>
        <div class="item">订单编号：{{ orderData.orderId }}</div>
        <div class="item">创建时间：{{ dateFormat(orderData.createTime) }}</div>
        <div class="item">微信交易号：{{ orderData.transactionId }}</div>
        <div class="item">支付时间：{{ dateFormat(orderData.payTime) }}</div>
      </div>
      <div class="td">
        <div class="item">封面</div>
        <div class="item">培训包名</div>
        <div class="item">数量</div>
        <div class="item">价格</div>
        <div class="item">有效期</div>
      </div>
      <div class="tr" v-if="orderData">
        <div class="item">
          <img class="cover" :src="orderData.coverImg" alt="" />
        </div>
        <div class="item">
          <div class="name">{{ orderData.taskName }}</div>
        </div>
        <div class="item">{{ orderData.buyNum }}</div>
        <div class="item">
          <div class="price">¥{{ orderData.price }}</div>
        </div>
        <div class="item">
          {{ orderData.effective }}
          <template v-if="orderData.dateType == 1">天</template>
          <template v-if="orderData.dateType == 2">个月</template>
          <template v-if="orderData.dateType == 3">年</template>
        </div>
        <div class="code-btn" v-if="orderData.buyType == 2">
          <span @click="codeShow = true">激活码</span>
        </div>
      </div>
      <div class="totals">
        总金额：<span>¥{{ orderData.totalPrice }}</span>
      </div>
    </div>
  </section>
  <a-drawer
    title="激活码列表"
    width="400"
    :visible="codeShow"
    @close="codeShow = false"
  >
    <div class="code-list">
      <div class="item" v-for="item in codeList" :key="item.code">
        <div class="row">
          <div>激活码：{{ item.code }}</div>
          <div :class="{ gary: item.useUid === 0 }">
            {{ item.useUid === 0 ? "未使用" : "已使用" }}
          </div>
        </div>
        <div class="row" v-if="item.useUid != 0">
          <div>使用人：{{ item.useUser }}</div>
        </div>
        <div class="row" v-if="item.useUid != 0">
          <div>使用时间：{{ dateFormat(item.useTime) }}</div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { orderInfo, orderCodes } from "@/api/user";
import { dateFormat } from "@/utils/tools";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const orderId = route.query.orderId;

    const orderData = ref({});
    orderInfo({ orderId }).then((res) => {
      orderData.value = res.data;
      if (res.data.buyType == 2) {
        getcodes();
      }
    });

    const codeShow = ref(false);
    const codeList = ref([]);
    const getcodes = () => {
      orderCodes({ orderId }).then((res) => {
        codeList.value = res.data;
      });
    };

    return {
      dateFormat,
      orderData,
      codeShow,
      codeList,
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 40px 0;
  .content {
    .mixinWrap(1080px);
    background-color: #fff;
    padding: 0 24px 24px;
    h3 {
      line-height: 64px;
      font-size: 18px;
      margin: 0;
    }
    .info {
      .mixinFlex(space-between);
      padding-bottom: 20px;
      flex-wrap: wrap;
      .item {
        width: 50%;
        line-height: 1.4;
        font-size: 14px;
        padding: 5px 0;
      }
    }
    .td {
      .mixinFlex(space-between; center);
      background-color: #eeeeee;
      line-height: 52px;
      padding: 0 24px;
      .item {
        width: 20%;
      }
    }
    .tr {
      .mixinFlex(space-between; center);
      border: 1px solid #eeeeee;
      border-top: 0;
      padding: 24px;
      position: relative;
      .item {
        width: 20%;
        .cover {
          width: 160px;
          height: 90px;
        }
        .name {
          width: 80%;
          word-break: break-all;
        }
        .price {
          color: #e63450;
        }
      }
      .code-btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100px;
        .mixinFlex(center; center);
        span {
          padding: 5px;
          color: @color-theme;
          cursor: pointer;
        }
      }
    }
    .totals {
      text-align: right;
      font-size: 16px;
      padding-top: 20px;
      span {
        color: #e63450;
        font-size: 20px;
      }
    }
  }
}

.code-list {
  .item {
    margin-bottom: 10px;

    .row {
      .mixinFlex(space-between);
      line-height: 1.5;
      font-size: 14px;

      .gary {
        color: #999;
      }
    }
  }
}
</style>